<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>
          <v-icon class="mr-3">mdi-account</v-icon>
          Perfil
          <v-spacer></v-spacer>
          <v-btn color="#3A33E8" dark :to="{ name: 'editProfile' }">
            <v-icon left>mdi-pencil</v-icon>
            Editar
          </v-btn>
        </v-card-title>
        <v-card-text class="text-data-visualization">
          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12">
              <strong>
                CNPJ:
              </strong>
              {{ profile.cnpj }}
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12">
              <strong>
                Nome da Empresa:
              </strong>
              {{ profile.companyName }}
            </v-col>

            <v-col lg="4" md="4" sm="12">
              <strong>
                Nome Fantasia:
              </strong>
              {{ profile.fantasyName }}
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12">
              <strong>
                Telefone:
              </strong>
              {{ profile.phone }}
            </v-col>

            <v-col xl="6" lg="6" md="6" sm="12">
              <strong>
                E-mail:
              </strong>
              {{ profile.email }}
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="12" lg="12" md="12" sm="12">
              <strong>
                CEP:
              </strong>
              {{ profile.zipcode }}
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="12" lg="12" md="12" sm="12">
              <strong>
                Endereço:
              </strong>
              {{ profile.street }}, {{ profile.number }}
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12">
              <strong>
                Complemento:
              </strong>
              {{ profile.addressComplement }}
            </v-col>

            <v-col xl="6" lg="6" md="6" sm="12">
              <strong>
                Bairro:
              </strong>
              {{ profile.district }}
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="6" lg="6" md="6" sm="12">
              <strong>
                Cidade:
              </strong>
              {{ profile.city }}
            </v-col>

            <v-col xl="6" lg="6" md="6" sm="12">
              <strong>
                Estado:
              </strong>
              {{ profile.state }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";

import API_URL from "@/common/config";

import {
  PROFILE_GET
  // PROFILE_DAYS_OFF_GET,
  // PROFILE_LOGO_GET
} from "@/store/actions/profile.type";
import {PROFILE_RESET_STATE} from "@/store/mutations/profile.type";
// import { ProfileService } from "@/common/api/profile.service";
// import { CHANGE_PROGRESS } from "@/store/mutations/mutations.type";

export default {
  name: "Profile",

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(PROFILE_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    await store.commit(PROFILE_RESET_STATE);
    await store.dispatch(PROFILE_GET);
    // await store.dispatch(PROFILE_DAYS_OFF_GET);

    return next();
  },
  data: () => ({
    active: false,
    apiUrl: API_URL,
    updateLogo: null
  }),
  // methods: {
  //   async previewImage(img) {
  //     if (img.target.files[0]) {
  //       store.commit(CHANGE_PROGRESS, true);
  //       await ProfileService.save_logo(img.target.files[0])
  //         .then(() => {
  //           store.commit(CHANGE_PROGRESS, false);

  //           this.snackbar.snackbar = true;
  //           this.snackbar.color = "success";
  //           this.snackbar.text = "Salvo com sucesso";
  //         })
  //         .catch(({ response }) => {
  //           store.commit(CHANGE_PROGRESS, false);

  //           this.snackbar.snackbar = true;
  //           this.snackbar.color = "error";
  //           this.snackbar.text = "Erro ao salvar Beauty Maker.";
  //           // console.log(response.data);
  //           if (typeof response.data.message !== "undefined") {
  //             this.snackbar.text =
  //               "Erro ao salvar Beauty Maker: " + response.data.message;
  //           }
  //         });
  //       store.commit(SET_LOGO, URL.createObjectURL(img.target.files[0]));
  //     }
  //   }
  // },
  computed: {
    ...mapGetters(["profile", "logo", "snackbar"])
  }
};
</script>

<style scoped>
.gridSys {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-2-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
